// src/components/Header.js

import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Link as ScrollLink } from 'react-scroll';
import { motion } from 'framer-motion';
import { Menu, X } from 'lucide-react';
import fonts from '../fonts';
import logo from '../images/mlw.png';

const Header = () => {
  const [isScrolled, setIsScrolled] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const location = useLocation();

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 50);
    };
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const menuItems = [
    { title: 'BuzzFlock', path: '/buzzflock' }, // New menu item
    { title: 'Luxury Eco Tourism', path: '/luxury-eco-tourism-strategy' },
    { title: 'Luxury Real Estate', path: '/luxury-real-estate-strategy' },
    { title: 'About', path: '/#about' },
    { title: 'Contact', path: '/#contact' },
  ];

  return (
    <motion.header
      className={`fixed top-0 left-0 right-0 z-50 transition-all duration-300 ${
        isScrolled ? 'bg-secondary shadow-md' : 'bg-transparent'
      }`}
      initial={{ y: -100 }}
      animate={{ y: 0 }}
      transition={{ duration: 0.5 }}
      style={{ height: '80px', fontFamily: fonts.primary }}
    >
      <div className="container mx-auto px-4 h-full flex justify-between items-center">
        <Link to="/" className="flex items-center">
          <img src={logo} alt="Montaigne Logo" className="h-16 w-auto" />
        </Link>
        <nav className="hidden md:block">
          <ul className="flex space-x-6">
            {menuItems.map((item, index) => (
              <li key={index}>
                {location.pathname === '/' && item.path.startsWith('/#') ? (
                  <ScrollLink
                    to={item.path.substring(2)}
                    smooth={true}
                    duration={500}
                    className={`hover:text-accent transition-colors ${
                      isScrolled ? 'text-lightText' : 'text-primary'
                    }`}
                    style={{ cursor: 'pointer', fontFamily: fonts.primary }}
                  >
                    {item.title}
                  </ScrollLink>
                ) : (
                  <Link
                    to={item.path}
                    className={`hover:text-accent transition-colors ${
                      isScrolled ? 'text-lightText' : 'text-primary'
                    }`}
                    style={{ fontFamily: fonts.primary }}
                  >
                    {item.title}
                  </Link>
                )}
              </li>
            ))}
          </ul>
        </nav>
        <div className="md:hidden">
          <button onClick={toggleMenu} className="text-primary" style={{ fontFamily: fonts.primary }}>
            {isMenuOpen ? <X size={24} /> : <Menu size={24} />}
          </button>
        </div>
      </div>
      {isMenuOpen && (
        <motion.div
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: -20 }}
          transition={{ duration: 0.3 }}
          className="md:hidden bg-secondary py-4"
          style={{ fontFamily: fonts.primary }}
        >
          <ul className="flex flex-col items-center space-y-4">
            {menuItems.map((item, index) => (
              <li key={index}>
                <Link
                  to={item.path}
                  className="text-primary hover:text-accent transition-colors"
                  onClick={() => setIsMenuOpen(false)}
                  style={{ fontFamily: fonts.primary }}
                >
                  {item.title}
                </Link>
              </li>
            ))}
          </ul>
        </motion.div>
      )}
    </motion.header>
  );
};

export default Header;
