import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import colors from '../colors';
import fonts from '../fonts';

const ProductionTeamSection = () => {
  const [isVideoLoaded, setIsVideoLoaded] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => setIsVideoLoaded(true), 2000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <section id="team" className="bg-secondary">
      <div className="container mx-auto px-4 md:px-8 py-12">
        <motion.h2
          className="text-3xl md:text-4xl font-bold mb-8 text-center"
          style={{ fontFamily: fonts.heading, color: colors.primary }}
          initial={{ y: 50, opacity: 0 }}
          whileInView={{ y: 0, opacity: 1 }}
          transition={{ duration: 0.5 }}
        >
          Meet Our <span style={{ color: colors.accent }}>Production Team</span>
        </motion.h2>
      </div>
      <div className="relative w-full overflow-hidden" style={{ paddingTop: '56.25%' }}>
        {!isVideoLoaded && (
          <div className="absolute inset-0 flex items-center justify-center bg-gray-900">
            <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-accent"></div>
          </div>
        )}
        <iframe
          src="https://www.youtube-nocookie.com/embed/vgKg5BWJuz0?autoplay=1&mute=1&loop=1&playlist=vgKg5BWJuz0&controls=0&showinfo=0&rel=0&modestbranding=1&iv_load_policy=3&disablekb=1&fs=0&color=white&hq=1&vq=hd1080"
          title="BuzzFlock Production Team"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          className={`absolute top-0 left-0 w-full h-full pointer-events-none ${isVideoLoaded ? 'opacity-100' : 'opacity-0'}`}
          style={{ objectFit: 'cover', transition: 'opacity 0.5s ease-in-out' }}
          onLoad={() => setIsVideoLoaded(true)}
        ></iframe>
      </div>
      <div className="bg-secondary py-16 px-4 md:px-8"> {/* Increased padding and added background */}
        <motion.p
          className="text-lg md:text-xl text-center max-w-3xl mx-auto"
          style={{ color: colors.lightText }}
          initial={{ y: 50, opacity: 0 }}
          whileInView={{ y: 0, opacity: 1 }}
          transition={{ duration: 0.5, delay: 0.4 }}
        >
          Our dedicated team of professionals works tirelessly to bring your brand stories to life, creating content that resonates and engages.
        </motion.p>
      </div>
    </section>
  );
};

export default ProductionTeamSection;