// src/colors.js

const colors = {
  primary: '#FFFFFF',
  secondary: '#121212',
  text: '#E0E0E0',
  lightText: '#B3B3B3',
  background: '#000000',
  accent: '#4F46E5', // Indigo for a modern look
  accentLight: '#6366F1',
  accentDark: '#3730A3',
  header: '#121212',
  headerGradient: 'linear-gradient(to right, #121212, #1F1F1F)',
  buttonText: '#FFFFFF',
  error: '#EF4444',
  success: '#22C55E',
  warning: '#F59E0B',
};

export default colors;
