// src/pages/HomePage.js

import React from 'react';
import { Helmet } from 'react-helmet';
import Header from '../components/Header';
import Footer from '../components/Footer';
import HeroSection from '../components/HeroSection';
import MilestonesSection from '../components/MilestonesSection';
import ScrollSection from '../components/ScrollSection';
import AboutSection from '../components/AboutSection';
import JourneyVideoSection from '../components/JourneyVideoSection';
import ServicesSection from '../components/ServicesSection';
import IndustriesSection from '../components/IndustriesSection';
import TestimonialsSection from '../components/TestimonialsSection';
import Newsletter from '../components/Newsletter';
import ContactForm from '../components/ContactForm';
import colors from '../colors';
import fonts from '../fonts';

const HomePage = () => {
  const achievements = [
    { title: "Encyclopedia of Aluminium & It's Alloys", image: "/images/achievements/encyclopedia.jpg" },
    { title: "India's Fastest Growing Luxury Second Home", image: "/images/achievements/luxofy.png" },
    { title: "India's Largest Aqua Farmer Network", image: "/images/achievements/ryrj.png" },
    { title: "Telangana's Largest Public Voting App", image: "/images/achievements/dmc.png" },
    { title: "North India's Largest Real Estate Aggregator", image: "/images/achievements/ix.png" }
  ];

  const clients = [
    { logo: '/images/clients/matrix.png' },
    { logo: '/images/clients/1acre.png' },
    { logo: '/images/clients/uc.png' },
    { logo: '/images/clients/luxofy.png' },
    { logo: '/images/clients/ix.png' },
    { logo: '/images/clients/mu.png' },
    { logo: '/images/clients/dmc.png' },
  ];

  return (
    <>
      <Helmet>
        <title>Montaigne - Innovate Today, Inspire Tomorrow</title>
        <meta
          name="description"
          content="Montaigne Smart Business Solutions blends scalable business architecture with acute business strategies to solve complex challenges and shape the future."
        />
        <link rel="canonical" href="https://www.montaigne.co" />
      </Helmet>
      <div className="bg-background text-text min-h-screen">
        <Header />
        <main>
          <HeroSection 
            title="Innovate Today Inspire Tomorrow"
            subtitle="We architect bespoke business solutions that propel industry leaders to the pinnacle of success."
            ctaText="Discover Our Approach"
            ctaLink="services"
          />
          <JourneyVideoSection />
          <MilestonesSection items={achievements} title="Our" subtitle="Milestones" />
          <AboutSection />
          <ServicesSection />
          <IndustriesSection />
          <ScrollSection items={clients} title="Our" subtitle="Partners" showTitles={false} />
          <TestimonialsSection />
          <NewsletterSection />
          <ContactSection />
        </main>
        <Footer />
      </div>
    </>
  );
};

const NewsletterSection = () => (
  <section id="newsletter" className="py-20 bg-texture section-gradient-2">
    <div className="container mx-auto px-4 md:px-8">
      <h2 className="text-4xl md:text-5xl font-bold mb-12 text-center" style={{ fontFamily: fonts.heading }}>
        <span style={{ color: colors.primary }}>Stay</span>{' '}
        <span style={{ color: colors.accent }}>Informed</span>
      </h2>
      <div className="max-w-lg mx-auto">
        <Newsletter />
      </div>
    </div>
  </section>
);

const ContactSection = () => (
  <section id="contact" className="py-20 bg-background">
    <div className="container mx-auto px-4 md:px-8">
      <h2 className="text-4xl md:text-5xl font-bold mb-12 text-center" style={{ color: colors.primary, fontFamily: fonts.heading }}>
        Transform Your <span className="text-accent">Business</span>
      </h2>
      <div className="max-w-lg mx-auto">
        <div className="bg-secondary p-8 rounded-lg shadow-lg">
          <ContactForm />
        </div>
      </div>
    </div>
  </section>
);

export default HomePage;