// src/pages/LuxuryEcoTourismPage.js
import React from 'react';
import { motion } from 'framer-motion';
import { Helmet } from 'react-helmet';
import Header from '../components/Header';
import Footer from '../components/Footer';
import HeroSection from '../components/HeroSection';
import Newsletter from '../components/Newsletter';
import ContactForm from '../components/ContactForm';
import { Mountain, Compass, CheckCircle, DollarSign, Sun, MapPin, Target, Book, Lightbulb, Leaf, Clock } from 'lucide-react';
import colors from '../colors';
import fonts from '../fonts';

const Section = React.memo(({ children, className, id, bgColor = 'bg-background' }) => (
  <section id={id} className={`py-20 ${className} ${bgColor}`}>
    <div className="container mx-auto px-4 md:px-8">{children}</div>
  </section>
));

const LuxuryEcoTourismPage = () => {
  const ogImageUrl = "https://images.unsplash.com/photo-1566073771259-6a8506099945?auto=format&fit=crop&w=1200&h=630&q=80";

  return (
    <>
      <Helmet>
        <title>Redefining Luxury Eco Tourism | Montaigne</title>
        <meta name="description" content="Explore Montaigne's innovative strategies for luxury eco tourism, blending opulence with sustainability to create unforgettable, environmentally conscious experiences." />
        <meta property="og:title" content="Redefining Luxury Eco Tourism | Montaigne" />
        <meta property="og:description" content="Discover how Montaigne is revolutionizing luxury eco tourism with sustainable, high-end experiences. Learn about our strategies for creating environmentally conscious, unforgettable journeys for discerning travelers." />
        <meta property="og:image" content={ogImageUrl} />
        <meta property="og:url" content="https://www.montaigne.co/luxury-eco-tourism-strategy" />
        <meta property="og:type" content="website" />
        <meta name="twitter:card" content="summary_large_image" />
        <link rel="canonical" href="https://www.montaigne.co/luxury-eco-tourism-strategy" />
      </Helmet>
      <div className="bg-background text-text min-h-screen">
        <Header />
        <main>
          <HeroSection 
            title="The Future of Luxury Eco Tourism"
            subtitle="Building your luxury eco tourism vision with innovative business architecture and strategy. Helping you set a new standard in green living."
            ctaText="What Can You Do Today?"
            ctaLink="insights"
          />
          <MarketTrendsSection />
          <ClientDemandsSection />
          <ActionableInsightsSection />
          <NewsletterSection />
          <ContactSection />
        </main>
        <Footer />
      </div>
    </>
  );
};

const MarketTrendsSection = () => {
  const trends = [
    {
      icon: Compass,
      title: "B Corp Certification",
      description: "Mahlatini Luxury Travel becomes first African specialist to secure B Corp certification. It's not just a gold star -- it's redefining business standards.",
    },
    {
      icon: DollarSign,
      title: "Hyper-Personalization",
      description: "OZEN COLLECTION in Maldives is dominating with their RESERVETM Experience. Private butlers, bespoke dining -- the works.",
    },
    {
      icon: Mountain,
      title: "Strategic Partnerships",
      description: "Destination Toronto's renewed Virtuoso partnership is a power play, tapping into 2,300 luxury travel advisors to showcase high-end experiences.",
    },
    {
      icon: Sun,
      title: "Asia-Pacific Growth",
      description: "Asia-Pacific set for highest luxury travel growth. If you're not looking East, you're potentially missing a gold mine.",
    },
    {
      icon: Leaf,
      title: "Sustainability as Core Offering",
      description: "From Atmosphere Foundation's coral restoration to IHG's Vignette Collection Maldives debut, sustainability isn't just nice-to-have -- it's becoming the main event.",
    },
    {
      icon: Clock,
      title: "Slow Travel Renaissance",
      description: "Longer, more immersive stays are becoming the preferred option for affluent travelers seeking deeper connections and experiences.",
    }
  ];

  return (
    <Section id="trends" className="py-20">
      <div className="container mx-auto px-4">
        <h2 className="text-4xl md:text-5xl font-bold mb-12 text-center text-primary">
          Market Trends Shaping <span className="text-accent">Luxury Eco Tourism</span>
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {trends.map((trend, index) => (
            <motion.div
              key={index}
              className="bg-secondary p-6 rounded-lg shadow-lg"
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: index * 0.1 }}
            >
              <trend.icon className="w-12 h-12 text-accent mb-4" />
              <h3 className="text-2xl font-semibold mb-4 text-primary">{trend.title}</h3>
              <p className="text-text">{trend.description}</p>
            </motion.div>
          ))}
        </div>
      </div>
    </Section>
  );
};

const ClientDemandsSection = () => {
  const demands = [
    {
      title: "Guilt-Free Luxury",
      description: "Luxury tourists want indulgence with zero environmental impact.",
      icon: CheckCircle,
    },
    {
      title: "Regenerative Experiences",
      description: "Create experiences that positively impact both the environment and local communities.",
      icon: MapPin,
    },
    {
      title: "Slow Travel",
      description: "Slow, immersive travel is now the ultimate form of luxury.",
      icon: Clock,
    },
    {
      title: "Authentic Luxury",
      description: "Modern travelers demand unique, culturally-rooted luxury experiences.",
      icon: Book,
    },
    {
      title: "Tech-Enabled Sustainability",
      description: "Sustainability enhanced through cutting-edge technology is the future of travel.",
      icon: Lightbulb,
    },
    {
      title: "Cultural Connection",
      description: "Travelers crave deeper connections with local cultures.",
      icon: Target,
    }
  ];

  return (
    <Section id="demands" className="py-20 bg-secondary">
      <div className="container mx-auto px-4">
        <h2 className="text-4xl md:text-5xl font-bold mb-12 text-center text-primary">
          What Your <span className="text-accent">Clients Are Demanding</span>
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {demands.map((demand, index) => (
            <motion.div
              key={index}
              className="bg-background p-6 rounded-lg shadow-lg"
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: index * 0.1 }}
            >
              <demand.icon className="w-12 h-12 text-accent mb-4" />
              <h3 className="text-2xl font-semibold mb-4 text-primary">{demand.title}</h3>
              <p className="text-text">{demand.description}</p>
            </motion.div>
          ))}
        </div>
      </div>
    </Section>
  );
};

const ActionableInsightsSection = () => {
  const insights = [
    {
      title: "Pursue B Corp Certification",
      description: "Secure a competitive edge with eco-conscious luxury certifications.",
      icon: Compass,
      benefit: "Enhance Brand Credibility"
    },
    {
      title: "Invest in Personalization Tech",
      description: "Implement AI-driven technology to offer hyper-personalized experiences.",
      icon: DollarSign,
      benefit: "Deliver Tailored Experiences"
    },
    {
      title: "Forge Strategic Partnerships",
      description: "Build relationships with luxury travel players to enhance your offerings.",
      icon: Mountain,
      benefit: "Broaden Your Reach"
    },
    {
      title: "Become a Thought Leader",
      description: "Position your brand as an expert in luxury sustainability.",
      icon: Lightbulb,
      benefit: "Strengthen Your Authority"
    },
    {
      title: "Design for Slow Travel",
      description: "Develop immersive, slow-travel itineraries for deeper connections.",
      icon: Sun,
      benefit: "Create Lasting Memories"
    },
    {
      title: "Invest in Sustainability Education",
      description: "Get certified in sustainable tourism to lead the eco-travel movement.",
      icon: Leaf,
      benefit: "Lead in Eco-Tourism"
    }
  ];

  return (
    <Section id="insights" className="py-20">
      <div className="container mx-auto px-4">
        <h2 className="text-4xl md:text-5xl font-bold mb-12 text-center text-primary">
          Your Next <span className="text-accent">Power Moves</span>
        </h2>
        <div className="space-y-8">
          {insights.map((insight, index) => (
            <motion.div
              key={index}
              className="bg-secondary p-6 rounded-lg shadow-lg"
              initial={{ opacity: 0, x: -50 }}
              whileInView={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.6, delay: index * 0.1 }}
            >
              <div className="flex items-center space-x-4 mb-4">
                <insight.icon className="w-12 h-12 text-accent" />
                <h3 className="text-2xl font-bold text-primary">{insight.title}</h3>
              </div>
              <p className="text-lg mb-2 text-text">{insight.description}</p>
              <p className="text-sm font-semibold text-accent">
                Benefit: {insight.benefit}
              </p>
            </motion.div>
          ))}
        </div>
      </div>
    </Section>
  );
};

const NewsletterSection = () => (
  <Section id="newsletter" className="bg-texture section-gradient-2">
    <h2 className="text-4xl md:text-5xl font-bold mb-12 text-center" style={{ fontFamily: fonts.heading }}>
      <span style={{ color: colors.primary }}>Stay</span>{' '}
      <span style={{ color: colors.accent }}>Informed</span>
    </h2>
    <div className="max-w-lg mx-auto">
      <Newsletter />
    </div>
  </Section>
);

const ContactSection = () => (
  <Section id="contact">
    <h2 className="text-4xl md:text-5xl font-bold mb-12 text-center text-primary">
      Let's Redefine <span className="text-accent">Luxury Eco Tourism</span> Together
    </h2>
    <div className="max-w-lg mx-auto">
      <div className="bg-secondary p-8 rounded-lg shadow-lg">
        <ContactForm />
      </div>
    </div>
  </Section>
);

export default LuxuryEcoTourismPage;